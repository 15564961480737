import * as React from 'react';
import '../App.css'
import { useNavigate } from 'react-router-dom';

interface IErrorProps {
}

const Error: React.FunctionComponent<IErrorProps> = (props) => {
    let navigate = useNavigate();
    const navigate_to_home=()=>{
        navigate("/")

    }

  return (
    <div className='fluid m-5 pt-3 appreciation-content'>
    <div className='display-6 mt-3'>
     Sorry! This page does not exist. 
    </div>

    <button type='button' className='primary mt-5' onClick={navigate_to_home}>Return to startpage </button>
   
</div>
  );
};

export default Error;
