import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {eng_de} from './lang_res';



interface IDemographicsProps {
    
}



const Demographics: React.FunctionComponent<IDemographicsProps> = (props) => {
    const dispatch = useDispatch();
    
    const language = eng_de
    const store:any= useSelector(data => data)
    

    const [isMMSStudent, setIsMMSStudent] = React.useState<any>(false)
    const handleIsMMSStudent=()=>{
        if(isMMSStudent===false){
            setIsMMSStudent(true)
            
        }else{
            setIsMMSStudent(false)
        }
    }
    //const [partData, setPartData] = React.useState<partDataTypes>()
    //let [error, setError] = React.useState<string>("")

    const handleSubmit =(e:React.FormEvent<HTMLFormElement>):void=>{
        e.preventDefault();
        // composeInputs
        
        let data ={
            age: (document.getElementById("age") as HTMLInputElement).value,
            gender: (document.getElementById("gender") as HTMLInputElement).value,
            vis_disability: (document.getElementById("vis_dis_state") as HTMLInputElement).value,
            vis_dis_type: (document.getElementById("vis_dis_type") as HTMLInputElement).value,
            phy_disability: (document.getElementById("phys_dis_state") as HTMLInputElement).value,
            phy_dis_type: (document.getElementById("phys_dis_type") as HTMLInputElement).value,
            std_mms_tub: isMMSStudent,
            date: (new Date()).toString(),
        }
        
        dispatch({type:"UPDATE_PARTDATA", payload:data})
        nav_to_page()
    }

    let navigate = useNavigate();
    
    const nav_to_page =()=>{
        navigate("/experiment")
    }
  return (
      <div className='fluid m-5 mt-5 p-2'>
          <h2 className='mt-5'>{store.lang==="DE"?language[29].de:language[29].eng}</h2>
          <h6 className='mb-3 mt-2'>{store.lang==="DE"?language[30].de:language[30].eng}</h6>
          <form className="row g-3 mt-3" onSubmit={(e)=>handleSubmit(e)}>
            <div className="col-md-6">
                <label className="form-label">{store.lang==="DE"?language[31].de:language[31].eng}</label>
                <input type="number" id="age" className="form-control" defaultValue={18} placeholder={store.lang==="DE"?language[32].de:language[32].eng} />
            </div>
            <div className="col-md-6">
                <label className="form-label">{store.lang==="DE"?language[33].de:language[33].eng}</label>
                <input type="text" className="form-control" id="gender" placeholder={store.lang==="DE"?language[34].de:language[34].eng}/>
            </div>
            
            <div className="col-md-4">
                <label className="form-label">{store.lang==="DE"?language[35].de:language[35].eng}</label>
                <select id="vis_dis_state"  className="form-select" defaultValue={"No"}>
                <option value={'Yes'}>{store.lang==="DE"?language[36].de:language[36].eng}</option>
                <option value={'No'}>{store.lang==="DE"?language[37].de:language[37].eng}</option>
                </select>
            </div>
            <div className="col-md-8">
                <label className="form-label">{store.lang==="DE"?language[38].de:language[38].eng}</label>
                <input type="text" id="vis_dis_type" className="form-control" placeholder={store.lang==="DE"?language[39].de:language[39].eng}/>
            </div>
            <div className="col-md-4">
                <label className="form-label">{store.lang==="DE"?language[40].de:language[40].eng}</label>
                <select id="phys_dis_state" className="form-select" defaultValue={"No"}>
                <option value={"Yes"}>{store.lang==="DE"?language[36].de:language[36].eng}</option>
                <option value={"No"}>{store.lang==="DE"?language[37].de:language[37].eng}</option>
                </select>
            </div>
            <div className="col-md-8">
                <label className="form-label">{store.lang==="DE"?language[41].de:language[41].eng}</label>
                <input type="text" className="form-control" id="phys_dis_type" placeholder={store.lang==="DE"?language[42].de:language[42].eng}/>
            </div>
            <div className="col-12">
                <div className="form-check">
                <input className="form-check-input" value={isMMSStudent} onChange={handleIsMMSStudent} type="checkbox" id="std_mms"/>
                <label className="form-check-label">
                    {store.lang==="DE"?language[43].de:language[43].eng}
                </label>
                </div>
            </div>

            <div className='d-grid gap-2 col-6 mx-auto mt-3 mb-5'>
                <button type='submit' className='btn btn-danger btn-lg mt-3'>{store.lang==="DE"?language[6].de:language[6].eng}</button>
            </div>
        
            </form>
      </div>
  );
};

export default Demographics;
