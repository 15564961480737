import * as React from 'react';
import '../App.css';
import {eng_de} from './lang_res';
import { useSelector } from 'react-redux';

interface IFooterProps {
}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
    const language = eng_de
    const store:any= useSelector(data => data)
  return (
    <div>
    <nav className="navbar navbar-light bg-light fixed-bottom" >
          <div className="container-fluid justify-content-center">
              <a href="https://www.mms.tu-berlin.de/menue/home/">
              <p className="text-muted text-center footer-text-custom">{store.lang==="DE"?language[7].de:language[7].eng}</p>
              </a>
          </div>
          </nav>
  </div>
  );
};

export default Footer;
