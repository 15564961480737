export interface mainState {
    partData: string[],
    interactionData: string[],
    lang: string,
}

const initialState = {
    partData:[],
    interactionData:[],
    lang:"ENG"
}

type Action_user = {type: "UPDATE_PARTDATA", payload: string}
type Action_result = {type: "UPDATE_INTERACTIONDATA", payload: string}
type Action_lang = {type: "CHANGE_LANG", payload: string}
    


export const mainReducer = ( state: mainState=initialState, action:Action_user| Action_result | Action_lang) =>{
    switch(action.type){
        case "UPDATE_PARTDATA":{
            return {...state, partData:[...state.partData, action.payload]}
        }
        case "UPDATE_INTERACTIONDATA":{
            return {...state, interactionData:[...state.interactionData, action.payload]}
        }
        case "CHANGE_LANG":{
            return {...state, lang:action.payload}
        }

        default:
            return state
    }
}