export const eng_de=[
    {
        no: 1,
        eng:"Language", 
        de:"Sprache"
    },
    {
        no: 2, 
        eng:"Experimental Exercise", 
        de:"Experimentelle Übung"
    },
    {
        no: 3, 
        eng:"This Experiment is to ascertain the possible reference domains for decision making during interaction with machine systems.", 
        de:"Mit diesem Experiment sollen die möglichen Bezugsbereiche für die Entscheidungsfindung bei der Interaktion mit Maschinensystemen ermittelt werden."
    },
    {
        no: 4, 
        eng:"It is part of doctorate research aimed at developing a conceptual guide to support intuitive interaction design for Human-Machine systems", 
        de:"Es ist Teil einer Doktorarbeit, die darauf abzielt, einen konzeptionellen Leitfaden zur Unterstützung der intuitiven Interaktionsgestaltung für Mensch-Maschine-Systeme zu entwickeln."
    },
    {
        no: 5, 
        eng:"To continue with participation, you need to read and accept the terms of engagement", 
        de:"Um mit der Teilnahme fortzufahren, müssen Sie die Teilnahmebedingungen lesen und akzeptieren"
    },
    {
        no: 6, 
        eng:"Terms of Engagement", 
        de:"Bedingungen des Engagements"
    },
    {
        no: 7, 
        eng:"Continue", 
        de:"Weiter"
    },
    {
        no: 8, 
        eng:"Human Machine Systems - Technical University, Berlin", 
        de:"Mensch-Maschine-Systeme - Technische Universität Berlin"
    },
    {
        no: 9, 
        eng:"Consent to participate", 
        de:"Einverständnis zur Teilnahme"
    },
    {
        no: 10, 
        eng:"I voluntarily agree to participate in this research study", 
        de:"Ich erkläre mich freiwillig bereit, an dieser Forschungsstudie teilzunehmen"
    },
    {
        no: 11, 
        eng:"I understand that even if I agree to participate now, I can withdraw at any time or refuse to answer any question without any consequences of any kind.", 
        de:"Mir ist bewusst, dass ich, auch wenn ich mich jetzt zur Teilnahme bereit erkläre, jederzeit zurücktreten oder die Beantwortung einer Frage verweigern kann, ohne dass dies irgendwelche Konsequenzen hat."
    },
    {
        no: 12, 
        eng:"I understand that I can withdraw permission to use data from my interaction within two weeks after the interview, in which case the material will be deleted.", 
        de:"Mir ist bekannt, dass ich die Erlaubnis zur Verwendung von Daten aus meiner Interaktion innerhalb von zwei Wochen nach dem Interview widerrufen kann; in diesem Fall wird das Material gelöscht."
    },
    {
        no: 13, 
        eng:"I have had the purpose and nature of the study explained to me in writing and I have had the opportunity to ask questions about the study.", 
        de:"Mir wurden Zweck und Art der Studie schriftlich erläutert, und ich hatte die Möglichkeit, Fragen zur Studie zu stellen."
    },
    {
        no: 14, 
        eng:"I understand that participation involves providing some of my personal information including age, gender, physical and visual disability information; interacting with a digital experiment which records my interactions inputs and participation time for the purposes of academic research.", 
        de:"Mir ist bewusst, dass die Teilnahme die Angabe einiger persönlicher Daten, wie Alter, Geschlecht, körperliche und visuelle Behinderungen, die Interaktion mit einem digitalen Experiment, das meine Interaktionen aufzeichnet, sowie die Teilnahmezeit zum Zwecke der wissenschaftlichen Forschung beinhaltet."
    },
    {
        no: 15, 
        eng:"I understand that all information I provide for this study will be treated confidentially.", 
        de:"Mir ist bekannt, dass alle Informationen, die ich für diese Studie zur Verfügung stelle, vertraulich behandelt werden."
    },
    {
        no: 16, 
        eng:"I understand that I will not benefit directly from participating in this research.", 
        de:"Mir ist klar, dass ich keinen direkten Nutzen aus der Teilnahme an dieser Untersuchung ziehen werde."
    },
    {
        no: 17, 
        eng:"I understand that in any report on the results of this research my identity will remain anonymous. This will be done by the researcher ensuring that, no personal information about me can be traceable back to me.", 
        de:"Mir ist bekannt, dass meine Identität in jedem Bericht über die Ergebnisse dieser Untersuchung anonym bleiben wird. Dies geschieht, indem der Forscher sicherstellt, dass keine persönlichen Informationen über mich zu mir zurückverfolgt werden können."
    },
    {
        no: 18, 
        eng:"I understand that the consent forms and original audio recordings will be retained by the researcher until 2 years after the academic work is completed", 
        de:"Mir ist bekannt, dass die Einverständniserklärung und die Original-Tonaufnahmen bis 2 Jahre nach Abschluss der wissenschaftlichen Arbeit vom Forscher aufbewahrt werden."
    },
    {
        no: 19, 
        eng:"I understand that under freedom of information legalisation I am entitled to access the information I have provided at any time while it is in storage as specified above.",
        de:"Mir ist bekannt, dass ich im Rahmen des Rechts auf Informationsfreiheit das Recht habe, jederzeit auf die von mir zur Verfügung gestellten Informationen zuzugreifen, solange sie wie oben angegeben gespeichert sind."
    },
    {
        no: 20, 
        eng:"I understand that I am free to contact any of the people involved in the research to seek further clarification and information.",
        de:"Mir ist bekannt, dass es mir freisteht, mich mit allen an der Forschung beteiligten Personen in Verbindung zu setzen, um weitere Klarstellungen und Informationen zu erhalten."
    },
    {
        no: 21, 
        eng:"I disagree",
        de:"Ich stimme nicht zu"
    },
    {
        no: 22, 
        eng:"Instructions",
        de:"Anweisungen"
    },
    {
        no: 23, 
        eng:"Duration: 4 mins | Mode: Digital Interaction (Clicking/Tapping and typing)",
        de:"Dauer: 4 Min. | Modus: Digitale Interaktion (Klicken und Tippen)"
    },
    {
        no: 24, 
        eng:"Premise",
        de:"Voraussetzung"
    },
    {
        no: 25, 
        eng:"Assuming you are on the first floor of a building and intend to use the elevator to go to the third -3rd floor.",
        de:"Angenommen, Sie befinden sich im ersten Stock eines Gebäudes und wollen den Aufzug benutzen, um in den dritten Stock zu gelangen."
    },
    {
        no: 26, 
        eng:"Task",
        de:"Aufgabe"
    },
    {
        no: 27, 
        eng:"Click/Tap on the appropriate color-labelled button to select the 3rd floor",
        de:"Klicken/Tippen Sie auf die entsprechende farblich gekennzeichnete Schaltfläche, um die dritte Etage auszuwählen."
    },
    {
        no: 28, 
        eng:"Explain briefly (in the text-box), the reason for your choice of button",
        de:"Erläutern Sie kurz (im Textfeld) den Grund für die Wahl der Taste"
    },
    {
        no: 29, 
        eng:"Timer only records the time it took to make a choice (excludes time for typing-in explanation)",
        de:"Der Timer zeichnet nur die Zeit auf, die benötigt wurde, um eine Wahl zu treffen (ohne die Zeit für die Eingabe der Erklärung)"
    },
    {
        no: 30, 
        eng:"Before you begin...",
        de:"Bevor Sie beginnen..."
    },
    {
        no: 31, 
        eng:"Kindly provide the following information about yourself.",
        de:"Bitte machen Sie die folgenden Angaben zu Ihrer Person."
    },
    {
        no: 32, 
        eng:"Age",
        de:"Alter"
    },
    {
        no: 33, 
        eng:"Enter Age...",
        de:"Alter eingeben..."
    },
    {
        no: 34, 
        eng:"Gender",
        de:"Geschlecht"
    },
    {
        no: 35, 
        eng:"Enter your Gender...",
        de:"Geben Sie Ihr Geschlecht ein..."
    },
    {
        no: 36, 
        eng:"Are you Visually disabled?",
        de:"Sind Sie sehbehindert?"
    },
    {
        no: 37, 
        eng:"Yes",
        de:"Ja"
    },
    {
        no: 38, 
        eng:"No",
        de:"Nein"
    },
    {
        no: 39, 
        eng:"Indicate your visual disability",
        de:"Geben Sie Ihre Sehbehinderung an"
    },
    {
        no: 40, 
        eng:"Visual disability type...",
        de:"Typ der Sehbehinderung..."
    },
    {
        no: 41, 
        eng:"Are you physically disabled?",
        de:"Sind Sie körperbehindert?"
    },
    {
        no: 42, 
        eng:"Indicate your physical disability",
        de:"Geben Sie Ihre körperliche Behinderung an"
    },
    {
        no: 43, 
        eng:"Physical disability type..",
        de:"Typ der körperlichen Behinderung..."
    },
    {
        no: 44, 
        eng:"I am a student of MMS-TU Berlin (Optional)",
        de:"Ich bin ein Student der MMS-TU Berlin (fakultativ)"
    },
    {
        no: 45, 
        eng:"Click a button to go to the third (3rd) floor.",
        de:"Klicken Sie auf eine Taste, um in den dritten (3.) Stock zu gehen."
    },
    {
        no: 46, 
        eng:"Yellow",
        de:"Gelb"
    },
    {
        no: 47, 
        eng:"Red",
        de:"Rot"
    },
    {
        no: 48, 
        eng:"Blue",
        de:"Blau"
    },
    {
        no: 49, 
        eng:"Pink",
        de:"Pink"
    },
    {
        no: 50, 
        eng:"You are here",
        de:"Sie sind hier"
    },
    {
        no: 51, 
        eng:"Green",
        de:"Grün"
    },
    {
        no: 52, 
        eng:"Briefly explain the reason for your choice.",
        de:"Erklären Sie kurz den Grund für Ihre Wahl."
    },
    {
        no: 53, 
        eng:"Type your explanation here...",
        de:"Geben Sie hier Ihre Erklärung ein..."
    },
    {
        no: 54, 
        eng:"Submit response",
        de:"Antwort abgeben"
    },
    {
        no: 55, 
        eng:"Thank you for your participation",
        de:"Vielen Dank für Ihre Teilnahme"
    },
    {
        no: 56, 
        eng:"Contact",
        de:"Kontakt"
    },
    {
        no: 57, 
        eng:"Researcher:",
        de:"Forscher:"
    },
    {
        no: 58, 
        eng:"Department of Psychology and Ergonomics (Human-Machine Systems)",
        de:"Institut für Psychologie und Arbeitswissenschaft (Fachgebiet Mensch-Maschine-Systeme)"
    },
    {
        no: 59, 
        eng:"3rd floor, room 3.004",
        de:"3. Stock, Raum 3.004"
    },
    {
        no: 60, 
        eng:"Marchstrasse. 23",
        de:"Marchstraße. 23"
    },
    {
        no: 61, 
        eng:"D-10587 Berlin",
        de:"D-10587 Berlin"
    },
    {
        no: 62, 
        eng:"nah(at)mms.tu-berlin.de",
        de:"nah(at)mms.tu-berlin.de"
    },
    {
        no: 63, 
        eng:"I agree",
        de:"Ich stimme zu"
    },
    {
        no: 64, 
        eng:"Data has been successfully saved!",
        de:"Daten wurden erfolgreich gespeichert!"
    },
    {
        no: 65, 
        eng:"Sorry. Experiment is no longer available",
        de:"Leider ist das Experiment nicht mehr verfügbar"
    },
    {
        no: 66, 
        eng:"Thank you",
        de:"Vielen Dank"
    },
]