import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {eng_de} from './lang_res';
import { useSelector } from 'react-redux';

interface IInstructionsProps {
}

const Instructions: React.FunctionComponent<IInstructionsProps> = (props) => {
    const language = eng_de
    const store:any= useSelector(data => data)
    let navigate = useNavigate();

    function nav_to_page(){
        navigate("/demograph") 
    }

  return (
      <div className='fluid m-5 mt-5 p-2'>
          <h1 className='mt-5 mb-3' >{store.lang==="DE"?language[21].de:language[21].eng}</h1>
            <div className="duration-cont">
            {store.lang==="DE"?language[22].de:language[22].eng}
            </div>
        <h4 className='mt-4'>{store.lang==="DE"?language[23].de:language[23].eng}</h4>
        <p>{store.lang==="DE"?language[24].de:language[24].eng}</p>
        <h4 className='mt-4'>{store.lang==="DE"?language[25].de:language[25].eng}</h4>
        <ol className="list-numbered">
            <li >{store.lang==="DE"?language[26].de:language[26].eng}</li>
            <li >{store.lang==="DE"?language[27].de:language[27].eng}</li>
        </ol>
        <span className="text-muted fst-italic mt-3" ><p>{store.lang==="DE"?language[28].de:language[28].eng}</p></span>

        <div className='d-grid gap-2 col-6 mx-auto mt-3 mb-5'>
            <button type='button' className='btn btn-danger btn-lg mt-3'
            onClick={nav_to_page}>{store.lang==="DE"?language[6].de:language[6].eng}</button>
        </div>
      </div>
  );
};

export default Instructions;
