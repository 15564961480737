import * as React from 'react';
import '../App.css'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {eng_de} from './lang_res';

import './parse-server/conn'
import Parse from 'parse'

interface IExperimentProps { 
    
}

type ExperimentValues ={
    now:number,
    startStimuliTime: number
}

var startStimuliTime:ExperimentValues["startStimuliTime"] = new Date().getTime()
const Experiment: React.FunctionComponent<IExperimentProps> = (props) => {

const language = eng_de
const store:any= useSelector(data => data)
    

const [selectedVal, setSelectedVal] = React.useState<string | null>()
const [selectedCol, setSelectedCol] = React.useState<string>("black")
const [selectionTime, setSelectionTime] = React.useState<number | undefined>()
const [alertToggle, setAlertToggle] = React.useState<boolean>(false)
const [explanationText, setExplanationText] = React.useState<string | undefined>()


function getDuration():number{
    let now: ExperimentValues["now"] = new Date().getTime();
    let duration = now-startStimuliTime
    return duration
}

let navigate = useNavigate();

// Create a new User
async function _send_to_server(to_be_saved:any) {

    const data = Parse.Object.extend("data");
    const DataEntry = new data();

    DataEntry.set("entrydate", new Date());
    DataEntry.set("data", to_be_saved);
    

    DataEntry.save().then((entry:any) => {
        //alert('New object created with objectId: ' + entry.id);
      }, (error:any) => {
        alert('Failed to create new object, with error code: ' + error.message);
      });
  }
    

const submit_response =()=>{
    if(selectedCol === 'black'){
        toggle_alert()
        
    }else{
        
        //dispatch({type:"UPDATE_INTERACTIONDATA", payload:{interaction_data}})
        console.log(store)

        let data = {
            participantData: store.partData,
            selectedCol: selectedCol,
            justification: explanationText,
            selection_time: selectionTime,
        }
        
        //push data to Server

        _send_to_server(data).then(()=>{
            //Then redirect to appreciation page
            navigate("/appreciation")
        })
    }
}

const onChangeChoice = (val:string)=>{
    
    let prefix = "You have selected "
    
    if(val!=="black"){
        setSelectedVal(prefix+val+ "!");
        setSelectedCol(val);
        setSelectionTime(getDuration())
    }else{
        setSelectedVal(null);
        setSelectedCol("black")
    }
    }

const onChangeExplanation =(e?:React.ChangeEvent<HTMLTextAreaElement>)=>{
    setExplanationText(e?.currentTarget.value)
}

function toggle_alert(){
    setAlertToggle(true)
}

   
  return (
      <div className='exp-container mt-5 fluid'>
          <div className='title-cont mt-3'>
            <text className='mt-5 inst-text'>1. {store.lang==="DE"?language[44].de:language[44].eng}<span className='alert'>{!alertToggle?"":"(Required!)"}</span> </text>
            <h4 style={{color:selectedCol}}>{selectedVal}</h4>
          </div>
          
          <div className='table-cont'>
          <table className="table table-borderless mt-3 align-middle table-custom">
            <tbody className='align-middle'>
                <tr>          
                <td className='align-text-center'>
                <button type="button" className='button-frame frame-yellow'>
                    <button type='button' className='button-tab button-tab-yellow'
                    onClick={()=>onChangeChoice("Yellow")}></button>
                    <text type='text' className='button-label'>{store.lang==="DE"?language[45].de:language[45].eng}</text>
                </button>
                </td>
                <td>
                <button type="button" className='button-frame frame-red'>
                    <button type='button' className='button-tab button-tab-red'
                    onClick={()=>onChangeChoice("Red")}></button>
                    <text type='text' className='button-label'>{store.lang==="DE"?language[46].de:language[46].eng}</text>
                </button>
                </td>
                </tr>
                <tr>          
                <td>
                <button type="button" className='button-frame frame-blue'>
                    <button type='button' className='button-tab button-tab-blue'
                    onClick={()=>onChangeChoice("Blue")}></button>
                    <text type='text' className='button-label'>{store.lang==="DE"?language[47].de:language[47].eng}</text>
                </button>
                </td>
                <td>
                <button type="button" className='button-frame frame-pink'>
                    <button type='button' className='button-tab button-tab-pink'
                    onClick={()=>onChangeChoice("Pink")}></button>
                    <text type='text' className='button-label'>{store.lang==="DE"?language[48].de:language[48].eng}</text>
                </button>
                </td>
                </tr>
                <tr>          
                <td>
                <button type="button" className='button-frame frame-black'>
                    <button type='button' className='button-tab button-tab-black'
                    onClick={()=>onChangeChoice("black")}></button>
                    <text type='text' className='button-label'><span className='f1-label h1'>1</span></text>
                    <text type='text' className='button-label'><span className='f1-label'>{store.lang==="DE"?language[49].de:language[49].eng}</span></text>
                </button>
                </td>
                <td>
                <button type="button" className='button-frame frame-green'>
                    <button type='button' className='button-tab button-tab-green'
                    onClick={()=>onChangeChoice("Green")}></button>
                    <text type='text' className='button-label'>{store.lang==="DE"?language[50].de:language[50].eng}</text>
                </button>
                </td>
                </tr>
                
            </tbody>
          </table>
          <div className="response-text-box mt-5">
            <label className='mb-4 mt-3 inst-text'>2. {store.lang==="DE"?language[51].de:language[51].eng}</label>
            <div className='comment-field-btn-cont mb-5'>
                <textarea className="form-control text-box-props text-box-custom" placeholder={store.lang==="DE"?language[52].de:language[52].eng} id="floatingTextarea" onChange={(e)=>onChangeExplanation(e)}></textarea>
                <button type="button" className="submit-btn-props submit-btn-custom mb-5"
                onClick={submit_response}>{store.lang==="DE"?language[53].de:language[53].eng}</button>
            </div>
           
            
            </div>
          </div>

      </div>
  ) ;
};

export default Experiment ;
