import * as React from 'react';
import '../App.css'
import Logo_MMS from '../img/mms_logo.jpg'
import Logo_TU from '../img/tub_logo.png'
import Lang_Icon from '../img/language-icon.jpg';
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import {eng_de} from './lang_res';

interface IHeaderProps {
}


const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const [lang, setLang] = useState("ENG")
  const dispatch = useDispatch()
  const language = eng_de

  const toggleLang =(command:string):void=>{
      if (command==="DE"){
        setLang("DE")
      }else{
        setLang("ENG")
      }

      dispatch({type:"CHANGE_LANG", payload:lang})
      

  }
  return (
      <div>
          <nav className="navbar-light bg-light fixed-top m-3 nav-all-holder">
                <div className="nav-bar-layout-custom">
                  <img src={Logo_TU} alt="mms_logo" width="60" height="30" className="logos "/>
                  <img src={Logo_MMS} alt="tub_logo" width="150" height="30" className="logos"/>
                </div>
                <div className="lang-setting-layout">
                  <img src={Lang_Icon} alt="tub_logo" width="20" height="20" />
                  <span className='bold-lang-label'>{lang==="DE"?language[0].de:language[0].eng}:</span>

                  <button className='lang-btn' onClick={()=>toggleLang("ENG")}>English</button>
                  <button className='lang-btn' onClick={()=>toggleLang("DE")}>Deutsch</button>
                </div>
            </nav>
        </div>
  );
};

export default Header;
