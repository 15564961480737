import { initializeParse } from '@parse/react';

initializeParse(
  'https://parseapi.back4app.com/',
  'fydEr9eJ5IBbpv38nfUbcspA8pTA1ByvyIPKS5xx',
  'Mw5DlXLxcr5NQCknQ9VCiyPXqnSeEtgMsH0UPzt3'
);

<script type="text/javascript" src="https://npmcdn.com/parse/dist/parse.min.js"></script>
   
