import React from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Header from './components/header'
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from './components/footer';
import Instructions from './components/instructions';
import Welcome from './components/welcome';
import Demographics from './components/demographics'
import Experiment from './components/experiment';
import Appreciation from './components/appreciation';
import Closed from './components/closed';
import Error from './components/error';
import  { Provider } from 'react-redux';
import { store } from './redux/store'

function App() {
  return (
    <Router>
      <Provider store={store}>
      <Header />
      <Routes>
          <Route path='/' element={<Closed />}/>
          <Route path='/instructions_nonfunctional34874' element={<Instructions />}/>
          <Route path='/demograph_febhf8948394' element={<Demographics />}/>
          <Route path='/experiment_%283838sddhbwj' element={<Experiment />}/>
          <Route path='/appreciation_4734729349%$$' element={<Appreciation />}/>
          <Route path='/welcome_537593hfs"!sjeh' element={<Welcome />}/>
          <Route path='/error_537593hfs"!sjeh' element={<Error />}/>
          <Route path='*' element={<Closed />}/>
      </Routes>
      <Footer />
      </Provider>
    </Router>
   
      
    
  );
}

export default App;
