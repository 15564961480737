import * as React from 'react';
import { useNavigate } from 'react-router-dom' 
import {eng_de} from './lang_res';
import { useSelector } from 'react-redux';

import '../App.css'


interface IWelcomeProps {
}

const Welcome: React.FunctionComponent<IWelcomeProps> = (props) => {
    let navigate = useNavigate();
    const language = eng_de
    const store:any= useSelector(data => data)

    const [agreed, setAgreed] = React.useState(false)

  

    const proceed =()=>{
        navigate("/instructions")  
    }

    function onAcceptAgreement(){
        setAgreed(true)
        
    }
    function onRejectAgreement(){
        setAgreed(false)
        
    }



  return (
  <div className='fluid m-5 p-2'>

<div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">{store.lang==="DE"?language[8].de:language[8].eng}</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body scrollable-agreement">
        <ol className="list-numbered">
            <li>{store.lang==="DE"?language[8].de:language[8].eng}</li>
            <li>{store.lang==="DE"?language[9].de:language[9].eng}</li>
            <li>{store.lang==="DE"?language[10].de:language[10].eng}</li>
            <li>{store.lang==="DE"?language[11].de:language[11].eng}</li>
            <li>{store.lang==="DE"?language[12].de:language[12].eng}</li>
            <li>{store.lang==="DE"?language[13].de:language[13].eng}</li>
            <li>{store.lang==="DE"?language[14].de:language[14].eng}</li>
            <li>{store.lang==="DE"?language[15].de:language[15].eng}</li>
            <li>{store.lang==="DE"?language[16].de:language[16].eng}</li>
            <li>{store.lang==="DE"?language[17].de:language[17].eng}</li>
            <li>{store.lang==="DE"?language[18].de:language[18].eng}</li>
        </ol>
      </div>
      <div className="modal-footer al">
        <button type="button" className="btn btn-secondary" onClick={onRejectAgreement} data-dismiss="modal">{store.lang==="DE"?language[20].de:language[20].eng}</button>
        <button type="button" className="btn btn-primary" onClick={onAcceptAgreement} data-dismiss="modal">{store.lang==="DE"?language[62].de:language[62].eng}</button>
      </div>
    </div>
  </div>
</div>
        
        <h1 className='mt-5 mb-2'>{store.lang==="DE"?language[1].de:language[1].eng}</h1>
        <h5 className='mt-4'>{store.lang==="DE"?language[2].de:language[2].eng}</h5>
        <h5 className='mt-2'>{store.lang==="DE"?language[3].de:language[3].eng}</h5>
        <p className='mt-5'>{store.lang==="DE"?language[4].de:language[4].eng}</p>
        
        <div className='terms-cond-btn'>
          <button type="button" className="btn btn-primary terms-cond-btn" data-toggle="modal" data-target="#exampleModal">
          {store.lang==="DE"?language[5].de:language[5].eng}
          </button>
        </div>
        

        <div className='d-grid gap-2 col-6 mx-auto mt-3 mb-5'>
            <button type='button' className={agreed?'btn btn-danger btn-lg mt-3':'btn btn-danger btn-lg mt-3 disabled'}
            onClick={proceed}>{store.lang==="DE"?language[6].de:language[6].eng}</button>
        </div>
        
      </div>
  );
};

export default Welcome;
