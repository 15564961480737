import * as React from 'react';
import '../App.css'
import { useSelector } from 'react-redux';
import {eng_de} from './lang_res';
interface IAppreciationProps {
}

const Appreciation: React.FunctionComponent<IAppreciationProps> = (props) => {

    const language = eng_de
    const store:any= useSelector(data => data)

  return (
      <div className='fluid m-5 pt-3 mt-5 appreciation-content'>
          <div className='display-6 mt-5'>
        <h4>{store.lang==="DE"?language[63].de:language[63].eng}</h4>
          {store.lang==="DE"?language[54].de:language[54].eng}
          </div>

          <div className='contact-card'>
              <h4>{store.lang==="DE"?language[55].de:language[55].eng}</h4>
              <text className='name-researcher-contact-card'>{store.lang==="DE"?language[56].de:language[56].eng} Noble Ahiaklo-Kuz (Mphil. BA)</text>
              <text>{store.lang==="DE"?language[57].de:language[57].eng}</text>
              <text>{store.lang==="DE"?language[58].de:language[58].eng}</text>
              <text>{store.lang==="DE"?language[59].de:language[59].eng}</text>
              <text>{store.lang==="DE"?language[60].de:language[60].eng}</text>
              <text id='email'>{store.lang==="DE"?language[61].de:language[61].eng}</text>
          </div>
          
      </div>
  );
};

export default Appreciation;
